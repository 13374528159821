import React, {useState, useEffect, CSSProperties } from 'react';
import logoLong from '../../assets/images/logo_full_length.png';
import logoSmall from '../../assets/images/logo.png';
import upscaledImage from '../../assets/images/upscaled.png';
import BottomNavigation from '../Common/BottomNavigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay } from 'swiper/modules';
import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom'
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { 
  FaHome, FaUserAlt, FaCog, FaClipboard, FaChartBar, FaTools, FaRegFileAlt, 
  FaUsers, FaUserTie, FaComments, FaBell, FaShoppingCart 
} from 'react-icons/fa';
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import { FiPackage } from "react-icons/fi";

const SideBar = () =>{
    const [collapsed, setCollapsed] = useState(false);
    const navigate = useNavigate();
    return(
<Sidebar
        collapsed={collapsed}
        style={{
          height: '100vh',
          width: collapsed ? '80px' : '250px',
          backgroundColor: '#fff',
          color: '#000',
          transition: 'width 0.3s ease',
        }}
      >
        <Menu>
          <MenuItem>
            <img src={collapsed ? logoSmall : logoLong} style={{width: collapsed? '100%' : '100%'}} />
          </MenuItem>
          <MenuItem
            icon={collapsed ? <AiOutlineArrowRight size={20}/> : <AiOutlineArrowLeft size={15}/>}
            onClick={() => setCollapsed(!collapsed)}
          >
            {collapsed ? 'Open' : 'Close'} Panel
          </MenuItem>

         
          <MenuItem icon={<FaHome size={collapsed ? 20 : 15}/>}>Home</MenuItem>

         
          <MenuItem icon={<FaUserAlt size={collapsed ? 20 : 15}/>}>Profile</MenuItem>

          
          <MenuItem icon={<FiPackage size={collapsed ? 20 : 15} />}>My Subscription</MenuItem>

         
          <SubMenu label="Clients" icon={<FaUsers size={collapsed ? 20 : 15}/>}>
            <MenuItem icon={<FaUserAlt />}>My Clients</MenuItem>
            <MenuItem icon={<FaRegFileAlt />}>Client Requests</MenuItem>
            <MenuItem icon={<FaComments />}>Client Feedback</MenuItem>
          </SubMenu>

          
          <SubMenu label="Agents" icon={<FaUserTie size={collapsed ? 20 : 15}/>}>
            <MenuItem icon={<FaUserAlt />}>My Agents</MenuItem>
            <MenuItem icon={<FaChartBar />}>Agent Performance</MenuItem>
          </SubMenu>

          
          <SubMenu label="Reports" icon={<FaClipboard size={collapsed ? 20 : 15}/>}>
            <MenuItem icon={<FaChartBar />}>Complaints Reports</MenuItem>
            <MenuItem icon={<FaRegFileAlt />}>Agent Performance</MenuItem>
            <MenuItem icon={<FaChartBar />}>Service Requests</MenuItem>
            <MenuItem icon={<FaTools />}>Revenue Reports</MenuItem>
            <MenuItem icon={<FaRegFileAlt />}>Subscription Analytics</MenuItem>
            <MenuItem icon={<FaChartBar />}>Client Interaction</MenuItem>
            <MenuItem icon={<FaTools />}>Product/Service Trends</MenuItem>
          </SubMenu>         
          
          <MenuItem icon={<FaComments size={collapsed ? 20 : 15}/>}>Chat & Communication</MenuItem>          
          <MenuItem icon={<FaShoppingCart size={collapsed ? 20 : 15}/>}>Marketplace</MenuItem>
          
          <SubMenu label="Settings" icon={<FaCog size={collapsed ? 20 : 15}/>}>
            <MenuItem>General</MenuItem>
            <MenuItem>Account</MenuItem>
          </SubMenu>
        </Menu>
      </Sidebar>
    )

}


export default SideBar;
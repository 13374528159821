import React, {useState, useEffect} from 'react';
import './Login.module.css';
import logo from '../../assets/images/logo_full_length.png';
import upscaledImage from '../../assets/images/upscaled.png';
import BottomNavigation from '../Common/BottomNavigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay } from 'swiper/modules';
import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom';

function Login() {
    const [showQRCode, setShowQRCode] = useState(true);
    const [isFlipped, setIsFlipped] = useState(false);
    const [flipped, setFlipped] = useState(false);
    const [taskLoader, setTaskLoader] = useState(false);

    const [loginError, setLoginError] = useState("");

    const navigate = useNavigate();

    const toggleFlip = () => {
      setIsFlipped(!isFlipped);
    };

    const closeErrorDialog = () => {
      setLoginError("");
    };
  
    function generateToken(length = 75) {
      const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const array = new Uint8Array(length);
      window.crypto.getRandomValues(array);
      return Array.from(array, (byte) => charset[byte % charset.length]).join('');
    }
    
    const handleLogin = async (e) => {
      e.preventDefault();
      const username = document.querySelector('input[placeholder="Username"]').value;
      const password = document.querySelector('input[placeholder="Password"]').value;
      const token = generateToken();
      if (!username || !password) {
        setLoginError("Username and password are required.");
        return;
      }
    
      setTaskLoader(true);
    
      try {
        const response = await fetch('https://access.myservicecircle.app/wcapi/2_manualLogin.php', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ username, password, token }),
        });
    
        const data = await response.json();
        console.log(data);
        if (data.success == 1) {
          localStorage.setItem('sp_unique', data.sp_unique);
          localStorage.setItem('token', data.token);
          navigate(`/Dashboard?token=${data.token}&spUnique=${data.sp_unique}`); // Pass token via URL
        } else {
          setLoginError('Invalid login credentials.');
        }
      } catch (error) {
        console.error("Login error:", error);
        setLoginError("An unexpected error occurred.");
      } finally {
        setTaskLoader(false);
      }
    };
    
    
 

  return (
    <>
    {loginError && (
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            borderRadius: '10px',
            padding: '20px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
            textAlign: 'center',
            zIndex: 1000,
          }}
        >
          <p style={{ color: '#993366', marginBottom: '20px' }}>{loginError}</p>
          <button
            onClick={closeErrorDialog}
            style={{
              padding: '10px 20px',
              backgroundColor: '#993366',
              color: '#fff',
              border: 'none',
              borderRadius: '8px',
              cursor: 'pointer',
            }}
          >
            Close
          </button>
        </div>
      )}

      <div style={{ width: '100vh', padding: 10, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
        <img src={logo} alt="Logo" style={{ width: '55vh' }} />
      </div>

      
      <div style={{ width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: '20px',paddingBottom:150 }} >
        <div style={{ width: '80%', minHeight: '60%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#fff', borderRadius: 15, boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', }} >
          
        <div style={{width: '50%',verflow: 'hidden',justifyContent: 'center',alignItems: 'center',paddingBottom: -10,position: 'relative',display: 'flex',flexDirection: 'column',}}>
           
            <img src={upscaledImage} alt="Image" style={{ width: '80%', borderTopLeftRadius: 10, objectFit: 'cover',  }} />

            <div style={{ width: '80%', height: '200px', position: 'absolute', top: '10%', left: '50%', transform: 'translateX(-50%)', zIndex: 10, color: '#fff', justifyContent: 'center', alignItems: 'center', borderRadius: 10, display: 'flex', overflow: 'hidden', boxShadow: '0px 30px 40px rgba(0, 0, 0, 0.2)', }} >
            <Swiper modules={[Autoplay]} autoplay={{ delay: 3000, disableOnInteraction: false }} loop={true} spaceBetween={0} slidesPerView={1} style={{ width: '100%', height: '100%', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',  }} >
                
                <SwiperSlide>
                <div style={{ backgroundColor: '#554694', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', }} >
                    <p style={{ fontSize: '18px', fontWeight: 'bold' }}>Why This Matters?</p>
                    <p style={{ fontSize: '14px', marginTop: '5px' }}>
                    Improve operational efficiency, boost customer satisfaction, and increase revenue by analyzing trends and optimizing resources.
                    </p>
                </div>
                </SwiperSlide>

                
                <SwiperSlide>
                <div style={{ backgroundColor: '#306DB3', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center',
                    alignItems: 'center', textAlign: 'center', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', }} >
                    <p style={{ fontSize: '18px', fontWeight: 'bold' }}>Service Requests Reports</p>
                    <p style={{ fontSize: '14px', marginTop: '5px' }}>
                    Gain insights on total service requests, resolution times, and categorize data by products or services.
                    </p>
                </div>
                </SwiperSlide>

                
                <SwiperSlide>
                <div style={{ backgroundColor: '#783086', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', }} >
                    <p style={{ fontSize: '18px', fontWeight: 'bold' }}>Complaint Reports</p>
                    <p style={{ fontSize: '14px', marginTop: '5px' }}>
                    Track total complaints, measure resolution success rates, and identify recurring issues for better service insights.
                    </p>
                </div>
                </SwiperSlide>

                
                <SwiperSlide>
                <div style={{ backgroundColor: '#FCC100', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', }} >
                    <p style={{ fontSize: '18px', fontWeight: 'bold' }}>Subscription Reports</p>
                    <p style={{ fontSize: '14px', marginTop: '5px' }}>
                    View active subscriptions, renewal dates, and revenue generated from various plans and add-ons.
                    </p>
                </div>
                </SwiperSlide>

                
                <SwiperSlide>
                <div style={{ backgroundColor: '#CE6081', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', }} >
                    <p style={{ fontSize: '18px', fontWeight: 'bold' }}>Revenue Reports</p>
                    <p style={{ fontSize: '14px', marginTop: '5px' }}>
                    Track revenue trends by month or year, break down by subscription types, and identify high-value clients.
                    </p>
                </div>
                </SwiperSlide>

                
                <SwiperSlide>
                <div style={{ backgroundColor: '#554694', width: '100%', height: '100%', display: 'flex', flexDirection: 'column',
                    justifyContent: 'center', alignItems: 'center', textAlign: 'center', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', }} >
                    <p style={{ fontSize: '18px', fontWeight: 'bold' }}>Client Interaction Reports</p>
                    <p style={{ fontSize: '14px', marginTop: '5px' }}>
                    Identify active vs. inactive clients, analyze feedback trends, and highlight high-value clients for better engagement.
                    </p>
                </div>
                </SwiperSlide>

                
                <SwiperSlide>
                <div style={{ backgroundColor: '#306DB3', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center',
                    alignItems: 'center', textAlign: 'center', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', }} >
                    <p style={{ fontSize: '18px', fontWeight: 'bold' }}>Product/Service Reports</p>
                    <p style={{ fontSize: '14px', marginTop: '5px' }}>
                    Discover the most serviced products, analyze trends, and improve quality for products with frequent complaints.
                    </p>
                </div>
                </SwiperSlide>

                
                <SwiperSlide>
                <div  style={{ backgroundColor: '#783086', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', }} >
                    <p style={{ fontSize: '18px', fontWeight: 'bold' }}>Agent Performance Reports</p>
                    <p style={{ fontSize: '14px', marginTop: '5px' }}>
                      Track agent tasks, analyze ratings, and optimize workload by identifying the most and least utilized agents.
                    </p>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>


            </div>

          
          <div style={{ width: '50%', padding: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent:'center'}} >
                <div  style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh",width:'100%' }} >
                  <motion.div style={{ width: "100%", height: "50vh", perspective: "1000px", display: "flex", justifyContent: "center", alignItems: "center",  textAlign: "center", }} >

                    <motion.div initial={{ rotateY: 0 }} animate={{ rotateY: isFlipped ? 180 : 0 }} transition={{ duration: 0.3 }} style={{ width: "80%", height: "50vh", position: "relative",transformStyle: "preserve-3d",borderRadius: "10px",boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.2)",backgroundColor: "#fff",display: "flex",justifyContent: "center", alignItems: "center",}}>    

                      <motion.div style={{position: "absolute",width: "80%", height: "50vh", backfaceVisibility: "hidden",  borderRadius: "10px",display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: "20px", textAlign: "center", }} >
                        <div style={{ width: "200px", height: "200px", backgroundColor: "#f2f2f2", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", }} >
                          <span style={{ color: "#aaa" }}>QR Code</span>
                        </div>
                        <p onClick={toggleFlip} style={{ marginTop: "20px", fontSize: "14px", color: "#993366", textDecoration: "underline", cursor: "pointer", }} >
                          Click here to sign in with username and password
                        </p>
                      </motion.div>
                      
                      <motion.div style={{ position: "absolute", width: "80%", height: "50vh", backfaceVisibility: "hidden", borderRadius: "10px",
                          display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: "20px", transform: "rotateY(180deg)",}}>
                        <form
                          style={{width: "100%",display: "flex",flexDirection: "column",justifyContent: "center",alignItems: "center",textAlign: "center",}} onSubmit={handleLogin}>
                          <input type="text" placeholder="Username" style={{ width: "100%", padding: "10px", margin: "10px 0", border: "1px solid #ccc", borderRadius: "8px", fontSize: "16px", }} />
                          <input type="password" placeholder="Password" style={{ width: "100%",padding: "10px",margin: "10px 0",border: "1px solid #ccc",borderRadius: "8px",fontSize: "16px",}}/>
                          <button type="submit" style={{ width: "100%", padding: "10px", backgroundColor: "#993366", color: "#fff", border: "none", borderRadius: "8px", fontSize: "16px", cursor: "pointer", marginTop: "10px", }} >
                            Login
                          </button>
                        </form>
                        <p onClick={toggleFlip} style={{ marginTop: "20px", fontSize: "14px", color: "#993366", textDecoration: "underline", cursor: "pointer", }} >
                          Click here to sign in using QR code
                        </p>
                      </motion.div>

                    </motion.div>
                    
                  </motion.div>
                </div>
          </div>

        </div>
      <BottomNavigation />
      
      </div>
      
    </>
  );
}

export default Login;
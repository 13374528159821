import React, {useState, useEffect, CSSProperties } from 'react';
import logo from '../../assets/images/logo_full_length.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay } from 'swiper/modules';
import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { MdLogout } from "react-icons/md";
import '../Dashboard/Dashboard.css';
import { BounceLoader, CircleLoader, ClipLoader, BeatLoader, BarLoader, FadeLoader } from 'react-spinners';

const HeaderBar = ({page_name}) =>{
    const [showLoader, setShowLoader] = useState(false);

    const navigate = useNavigate();
    const Logout = () =>{
        setShowLoader(true);
          localStorage.removeItem('token');
          localStorage.removeItem('sp_unique');
          localStorage.removeItem('isAuthenticated');
  
          setTimeout(() => {
            setShowLoader(false);
            navigate('/login');
        }, 3000);
      }
  
    return(
        <>
        <div style={{width:'100%',display:'flex',justifyContent:'flex-start',alignItems:'center',flexDirection:'row'}}>
            <div style={{width:'50%',display:'flex',justifyContent:'flex-start',alignItems:'center',marginTop:'-20px'}}>
                <h4 style={{color:'#8c8c8c',fontFamily:'Montserrat'}}>{page_name}</h4>
            </div>
            <div style={{width:'50%',display:'flex',justifyContent:'flex-end',alignItems:'center',marginTop:'-20px'}}>            
                <MdLogout size={25} style={{marginRight:20,color:'#8c8c8c',cursor:'pointer'}} onClick={Logout}/>
            </div>
        </div>
        {showLoader &&
            <div className='loaderMask'>
            <div className='loaderBox'>
              <CircleLoader color='#FCC100' size={30} />
            </div>          
            </div>
          }
          </>
    )
}

export default HeaderBar;
import React, {useState, useEffect, CSSProperties } from 'react';
import logoLong from '../../assets/images/logo_full_length.png';
import logoSmall from '../../assets/images/logo.png';
import upscaledImage from '../../assets/images/upscaled.png';
import BottomNavigation from '../Common/BottomNavigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import Login from '../Login/Login';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay } from 'swiper/modules';
import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom'
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { 
  FaHome, FaUserAlt, FaCog, FaClipboard, FaChartBar, FaTools, FaRegFileAlt, 
  FaUsers, FaUserTie, FaComments, FaBell, FaShoppingCart 
} from 'react-icons/fa';
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import { FiPackage } from "react-icons/fi";
import HeaderBar from '../Common/HeaderBar';
import SideBar from '../Common/SideBar';

import './Dashboard.css';

import { BounceLoader, CircleLoader, ClipLoader, BeatLoader, BarLoader, FadeLoader } from 'react-spinners';
import { EditablePropsProvider } from '@chakra-ui/react';

const Dashboard = () =>{
  const { token } = useParams();
  const [showLoader, setShowLoader] = useState(false);
  const [taskLoader, setTaskLoader] = useState(false);
  const [sp_unique, setSpUnique] = useState(null);
  const [basicError, setBasicError] = useState('');
  const [currentPlan, setCurrentPlan] = useState(null);
  const [allPlans, setAllPlans] = useState(null);
  const [planActive, setPlanActive] = useState(true);
  const [country, setCountry] = useState(null);
  const [showPlanExpiry, setShowPlanExpiry] = useState(false);
  const [showPlanSupport, setShowPlanSupport] = useState(false);
  useEffect(() => {
   if(token){
    fetchDetails();
    
   }
  }, [token]);    
    const navigate = useNavigate();

    const fetchDetails = async() =>{
        setTaskLoader(true);

        try{
          const response = await fetch('https://access.myservicecircle.app/wcapi/3_verifyDashboard.php',{
            method:'POST',
            headers:{
              'Content-Type' : 'application/json'
            },
            body:JSON.stringify({token})
          });

          const responseData = await response.json();

          if(responseData.success == 1){
            setSpUnique(responseData.sp_unique);            
          }else{
            navigate('/Login');
          }
        }catch(error){
          console.log(error);
        }finally{
          setTaskLoader(false);
        }
    }

    useEffect(()=>{
      if(sp_unique){
        fetchController();
      }
    },[sp_unique]);

    const fetchController = async () =>{
      setTaskLoader(true);
      try{
        const response = await fetch('https://access.myservicecircle.app/sp/7a_fetchController.php',{
          method:'POST',
          headers:{
            'Content-Type' : 'application/json'
          },
          body: JSON.stringify({sp_unique})
        });

        const responseData = await response.json();

        setCurrentPlan(responseData.plan);
        setAllPlans(responseData.all_plans);
        setCountry(responseData.country);
        setPlanActive(responseData.is_active>1 ? true : false);
       
      }catch(error){
        console.log(error);
      }finally{
        setTaskLoader(false);
      }
    }
    const fetchDashBoardCount = async ()=>{
      
    }

    useEffect(()=>{
      if(planActive){
        fetchDashBoardCount();
      }else{
        setShowPlanExpiry(true);
      }
    },[planActive]);

    
    return (
      <>
        <div style={{ display: 'flex', height: '100vh'}}>
          <SideBar />
            <div style={{flex: 1,overflowY: 'auto',backgroundColor:'#e6e6e6'}}>
              <div style={{width:'100%',justifyContent:'flex-start',alignItems:'flex-start',padding:10}}>
                  <HeaderBar page_name={'Dashboard'} />
                  <h3>Test</h3>
              </div>
              
            </div>
          
        </div>
          {showPlanExpiry && !planActive &&
            <div className='loaderMask'>

            </div>
          } 
          {showPlanSupport &&
          <div className='loaderMask'>

          </div>
          }
          {taskLoader &&
           
            <div className='loaderBox'>
              <CircleLoader color='#FCC100' size={30} />
            </div>          
           
          }
          {basicError &&
          <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            borderRadius: '10px',
            padding: '20px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
            textAlign: 'center',
            zIndex: 1000,
          }}
        >
          <p style={{ color: '#000', marginBottom: '20px' }}>{basicError}</p>
          <button
            onClick={()=>setBasicError('')}
            style={{
              padding: '10px 20px',
              backgroundColor: '#400982',
              color: '#fff',
              border: 'none',
              borderRadius: '8px',
              cursor: 'pointer',
            }}
          >
            Close
          </button>
        </div>
          }
        </>
      );
}


export default Dashboard;
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './BottomNavigation.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

const BottomNavigation = ()=>{

    return(
        <div style={{width:'100%',padding:5,justifyContent:'center',alignItems:'center',flexDirection:'column',backgroundColor:'#FCC100',zIndex:100,position:'fixed',bottom:0,}}>
            <div style={{display:'flex',gap:20,justifyContent:'center',alignItems:'center',flexDirection:'row',flexWrap:'wrap'}}>
                <a href="https://myservicecircle.app/TermsOfUsage" className="bottomLinks">Terms of usage</a>
                <a href="https://myservicecircle.app/RefundPolicy">Refund Policy</a>
                <a href="https://myservicecircle.app/PrivacyPolicy">Privacy Policy</a>
                <a href="https://myservicecircle.app/Disclaimer">Disclaimer</a>
            </div>
        </div>
    )
}

export default BottomNavigation;